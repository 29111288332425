import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
// import DOMPurify from "dompurify"
// const { convert } = require('html-to-text');
import { baseUrl } from "../../apiContent/api.jsx";
import { Avatar, CircularProgress, ListItem, ListItemText } from '@material-ui/core';
import { AssignmentIndOutlined } from '@material-ui/icons';
import useInfiniteScroll from "../../sharedThinkly/common/infiniteScoll.jsx";
import $ from "jquery";
import NoData from "./noData.jsx";

const Feed = (props) => {
    const BASE_URL = useContext(baseUrl);
    const [thinkliesList, setthinkliesList] = useState();
    const [Author_ID, setAuthor_ID] = useState()
    const [isFetching, setIsFetching] = useInfiniteScroll(scrollThinklies);
    const [startIndexValue, setstartIndexValue] = useState(0)
    const [endIndexValue, setendIndexValue] = useState(10)
    const [contentData, setcontentData] = useState()
    const [NoRecord, setNoRecord] = useState(false)

    useEffect(() => {
        if (props.authorID !== undefined) {
            console.log("props author ID inside feed page", props.authorID);
            setAuthor_ID(props.authorID)
            fetchThinklies(props.authorID);
        }
    }, []);

    function scrollThinklies() {
        console.log("inside scroll thinklie function");
        if (isFetching === true) {
            console.log("fetch thiklies status is true, getting load");
            setstartIndexValue(endIndexValue + 1)
            setendIndexValue(endIndexValue + 10)
        }
    }

    useEffect(() => {
        console.log("startIndexValue", startIndexValue, "endIndexValue", endIndexValue);
        fetchThinklies(Author_ID)
    }, [startIndexValue, endIndexValue])

    function fetchThinklies(author_id) {
        console.log("fetch thinklies");
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": author_id
            },
            data: {
                "UserID": author_id,
                "ThinklyID": "",
                "StartIndex": startIndexValue,
                "EndIndex": endIndexValue
            }
        };
        Axios(`${BASE_URL}Thinkly/GetUserThinklies`, config)
            .then((res) => {
                console.log("GetUserThinklies response@@@@", res);
                if (res.data.responseCode === '00') {
                    console.log("GetUserThinklies response inside responseCode 00", res.data.responseData);
                    setthinkliesList(res.data.responseData)
                    setIsFetching(false) //
                } else if (res.data.responseCode === '03') {
                    setNoRecord(true)
                }
            })
            .catch((err) => {
                console.log("GetUserThinklies error in catch", err);
            });
    }

    useEffect(() => {
        console.log("thinklies List before if@@@ ", thinkliesList);
        if (thinkliesList !== undefined) {
            console.log("thinklies List after if@@@ ", thinkliesList);
        }
    }, [thinkliesList]);

    const openThinkly = (data, ID) => {
        console.log("thinkly title", data, ID);
        const title = data.trimEnd()
        const thinkly_title = title.replaceAll(' ', '-')
        window.open(`/thinkly/${thinkly_title}/${ID}`, '_blank')
    }

    return (<>
        {thinkliesList !== undefined && thinkliesList !== null && thinkliesList.length > 0 ? <>
            <h3>My Thinklies</h3> <hr />
            {thinkliesList.map((obj, index) => {
                const count = 30;
                const thinkly_name = obj.authorData.authorProfileText.slice(0, count) + (obj.authorData.authorProfileText.length > count ? "..." : "");
                var image_url = obj.postData.postImages[0]  //show once image only
                var final_time = ""
                var date1 = new Date(obj.postData.postUpdatedDateTime); //post date & time
                var date2 = new Date; //current date & time
                var Difference_In_Time = date2.getTime() - date1.getTime();  //substraction of current date to post date
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);  // number of days in long float
                const post_Date_Time = parseFloat(Difference_In_Days).toFixed(2);  //fixed decimal to 2
                if (Math.floor(post_Date_Time) < 1) {
                    const difference = Difference_In_Time / (1000 * 60 * 60);
                    const hours = Math.abs(Math.round(difference))
                    if (hours > 1) {
                        final_time = hours + " hours ago"
                    } else {
                        final_time = hours + " hour ago"
                    }
                } else {
                    const days = Math.floor(post_Date_Time);
                    final_time = days + " days ago"
                }
                return (<>
                    <div className='row' key={index}>
                        <div className='col-8'>
                            <ListItem>
                                {obj.authorData.authorProfileImage !== undefined && obj.authorData.authorProfileImage !== null ?
                                    <img alt="user profile" style={{ width: '50px', height: '50px', borderRadius: '50%', marginBottom: '16px' }} src={obj.authorData.authorProfileImage.charAt(0) === '@' ? obj.authorData.authorProfileImage.substring(1) : obj.authorData.authorProfileImage} />
                                    : <Avatar style={{ width: '50px', height: '50px', marginBottom: '16px' }} src={<AssignmentIndOutlined />} />
                                }
                                <ListItemText className='ml-3'
                                    primary={<div style={{ lineHeight: '1', fontSize: '16px' }}>
                                        <b>{obj.authorData.authorName}</b>
                                    </div>}
                                    secondary={<div style={{ lineHeight: '1' }}>
                                        <span style={{ fontSize: '12px' }}>{thinkly_name}</span>
                                        <p style={{ fontSize: '10px' }}>{final_time}</p>
                                    </div>} />
                            </ListItem>
                        </div>
                        <div className='col-4'>
                            <p className='mt-2 float-right font-weight-bold' style={{ fontSize: '28px', color: 'gray' }} >...</p>
                        </div>
                    </div>
                    <div className='row ml-3'>
                        <div className='col-10'>
                            <p style={{ fontSize: '20px', fontFamily: 'Lora' }}>{obj.postData.postTitle}</p>
                        </div>
                        <div className='col-2'>
                            {obj.postData.postImages !== undefined && obj.postData.postImages.length > 0 &&
                                <img alt="thinkly Image" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px' }} src={image_url.charAt(0) === '@' ? image_url.substring(1) : image_url} />
                            }
                        </div>
                    </div>
                    <div className='row ml-3' style={{ fontSize: '16px' }}>
                        <div className='col-12'>
                            <span className='mt-4' style={{ color: '#3c7493', fontSize: '12px' }}>{obj.postData.subcategoryname.replaceAll(',', ' | ')}</span>
                        </div>
                        <div className='col-12' id="thinkly-content" onClick={() => openThinkly(obj.postData.postTitle, obj.postData.postID)} dangerouslySetInnerHTML={{ __html: obj.postData.postDescription.slice(0, 370) + (obj.postData.postDescription.length > 370 ? '<b> ...read more</b>' : "") }} />
                    </div>
                    <hr />
                </>)
            })}
        </> : NoRecord === true ? <NoData /> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
            <CircularProgress aria-label="Loading..." />
        </div>}
        {isFetching && 'Fetching more list items...'}
    </>);
}

export default Feed;


// function AddReadMore(index) {
//     console.log("index@@@@@@@@@@@@@@@@@@@@", index);
//     var carLmt = 300;
//     var readMoreTxt = " ... Read More";
//     var readLessTxt = " Read Less";
//     $(".addReadMore").each(function () {
//         if ($(this).find(".firstSec").length)
//             return;

//         // var allstr = document.getElementById("thinkly-content").getAttribute('value')
//         // var allstr = document.getElementById(`thinkly-content-${index}`).textContent
//         var allstr = document.getElementById("thinkly-content").textContent;
//         console.log("thinkly content@@@@@@", allstr.length(120));
//         if (allstr.length > carLmt) {
//             var firstSet = allstr.substring(0, carLmt);
//             var secdHalf = allstr.substring(carLmt, allstr.length);
//             var strtoadd = firstSet + "<span class='SecSec'>" + secdHalf + "</span><span class='readMore'  title='Click to Show More'>" + readMoreTxt + "</span><span class='readLess' title='Click to Show Less'>" + readLessTxt + "</span>";
//             $(this).html(strtoadd);
//         }

//     });
//     // Read More and Read Less Click Event binding
//     $(document).on("click", ".readMore,.readLess", function () {
//         $(this).closest(".addReadMore").toggleClass("showlesscontent showmorecontent");
//     });
// }