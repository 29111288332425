import firebase from 'firebase/compat/app'

export const register = async ({ email }) => {
    console.log("inside .then", email, firebase);
    const actionCodeSettings = {
        url: process.env.REACT_APP_ACTIONCODE_URL + email,
        handleCodeInApp: true
        // dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN
    };
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
            console.log("sendSignInLinkToEmail mail ::", actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
        })
        .catch((err) => {
            console.log(err);
        })
}

export const authenticateIsUser = async (email) => {
    console.log("inside authenticateIsUser function1");
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        console.log("inside if isSignInWithEmailLink condition -> authenticateIsUser function1");
        await firebase.auth().signInWithEmailLink(email, window.location.href)
            .then((result) => {
                console.log("inside .then -> authenticateIsUser function1", result);
                window.sessionStorage.setItem('signInStatus', 'Success');
            })
            .catch((error) => {
                window.localStorage.setItem('signInStatus', error.code);
                console.log(error);
            });
    }
}



