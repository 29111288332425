import React, { useEffect, useState, useContext } from 'react'
import Axios from "axios";
import { baseUrl } from "../../apiContent/api.jsx";
import { Avatar, ListItemText, CircularProgress } from "@material-ui/core"
import { AssignmentIndOutlined, ArrowForwardIosRounded } from "@material-ui/icons"
import NoData from './noData.jsx';
import { Card } from 'react-bootstrap'

const Publication = (props) => {
    const BASE_URL = useContext(baseUrl);
    const [authorID, setauthorID] = useState()
    const [PublicationByAuthorData, setPublicationByAuthorData] = useState()
    const [NoRecord, setNoRecord] = useState(false)

    useEffect(() => {
        if (props.authorID !== undefined && props.authorID !== null) {
            console.log("inside publication page props data of author ID", props.authorID);
            // setauthorID(props.authorID)
            getPublicationByAuthor(props.authorID)

        }
    }, [])


    function getPublicationByAuthor(authorID) {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": "21723"
            },
            data: {
                "UserID": authorID,
                "StartIndex": 0,
                "EndIndex": 10
            }
        };
        Axios(`${BASE_URL}User/GetUserPublications/`, config)
            .then((res) => {
                console.log(res);
                if (res.data.responseCode === '00') {
                    console.log("setPublicationByAuthorData @@@@@@@@", res.data.responseData);
                    setPublicationByAuthorData(res.data.responseData)
                } else if (res.data.responseCode === '03') {
                    setNoRecord(true)
                }
            })
            .catch((err) => {
                console.log("setPublicationByAuthorData error in catch", err);
            });
    }

    const openPublication = (pen_name) => {
        console.log("on click of publication@@@@", pen_name);
        window.open(`/publication/${pen_name}`, '_blank')
    }

    return (<>
        <div className='container'>
            {PublicationByAuthorData !== undefined && PublicationByAuthorData !== null ? <>
                <h3>My Publications</h3> <hr />
                {PublicationByAuthorData.map((obj, index) => {
                    const name = obj
                    console.log("data", name)
                    return (<Card className='p-3' key={index} style={{ padding: '10px 20px', border: "none", borderRadius: '10px', }}>
                        <div className='row' onClick={() => openPublication()}>
                            <div className='col-3'>
                                {obj.publicationImage !== undefined ?
                                    <img alt="publication Image" style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%' }}
                                        src={obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage} />
                                    : <Avatar style={{ width: '120px', height: '120px' }} src={<AssignmentIndOutlined />} />
                                }
                            </div>
                            <div className='col-8'>
                                <ListItemText style={{ marginLeft: '-55px', marginTop: '27px' }}
                                    primary={<h4 style={{ lineHeight: '0.5' }}>{obj.publicationName}</h4>}
                                    secondary={<text>{obj.about}</text>}
                                />
                            </div>
                            {/* <div className='col-1 d-flex flex-column justify-content-center align-items-center'>
                            <ArrowForwardIosRounded style={{ color: 'gray', fontSize: '50px', fontStyle: 'normal' }} />
                        </div> */}
                        </div>
                    </Card>)
                })}
            </> : NoRecord === true ? <NoData /> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
                <CircularProgress aria-label="Loading..." />
            </div>}
        </div>
    </>)
}

export default Publication

