import { Card } from '@material-ui/core'

const LoveGuruFaq = () => {

    return (<div className='row'>
        <div className='col-12'>
            <p className='font-weight-bold fs-22'> FAQs</p>
            <Card className='mb-4' style={{ boxShadow: 'none' }}>
                <p className='fw-bold fs-20'>Why send Stars?</p>
                <p className='fs-18'>To show your love to RJ Love Guru and get a personalized thank you letter from him.
                    <br />
                    <p>*The letter will be sent out within 24hrs after sending the star(s)</p>
                </p>
                <p className='fw-bold fs-20 mt-2'>What is the cost of each Star?</p>
                <p className='fs-18'>1 Star = ₹10
                    <br />3 Stars = ₹30
                    <br />5 Stars = ₹50
                    <br />10 Stars = ₹100
                </p>
                <p className='fw-bold fs-20'>What is the maximum number of Stars that can be sent at a time?</p>
                <p className='fs-18'>
                    10 stars at a time. This can be repeated multiple times.
                </p>
                <p className='fw-bold fs-20'>What are the options to pay?</p>
                <p className='fs-18'>
                    The Thinkly payment options are flexible, choose between bank transfer, credit or debit card or simply GPay / PhonePe.
                </p>
                <p className='fw-bold fs-20'>What is the proof that the money reached the content-creator?</p>
                <p className='fs-18'>
                    You will get a personalized thank you letter from Love Guru each time you thank him with Thinkly Stars.
                </p>
                <p className='fw-bold fs-20'>Where do I reach out for support?</p>
                <p className='fs-18'>
                    We are available 24/7 via email support. Write to us at stars@thinkly.me and we will reach out to you ASAP.
                </p>


                <p>Download the app: <a href="https://thinkly.me/download.html">Thinkly</a></p>
                {/* <p className='fw-bold fs-20'>About Radio City</p>
                <p className='fs-18'>
                    Radio City, is the first FM radio broadcaster in India with over two decades of expertise in the radio industry, Radio City has consistently been the No.1 radio station in Bengaluru with 25.2% and No.2 in Mumbai with 14.3% average listenership share respectively. (Source: RAM Data, TG: 12+ Period: Week 1, 2013 to Week 52, 2022). Radio City Delhi ranks #3 with 11.7% average listenership share (Source: RAM Data, TG: 12+ Period: Week 1, 2019 to Week 52, 2022). Music Broadcast Limited currently has 39 stations across 12 states, comprising 62% of the country’s FM population. Radio City reaches out to over 69 million listeners across India covered by AZ Research 2019 (Source: AZ Research Report). Radio City has bagged over 150 awards across renowned national and international platforms such as
                    New York Festivals Radio Awards, ACEF Global Customer Engagement Forum & Awards, Golden Mikes - Radio & Audio Awards, India Audio Summit and Awards, India Radio Forum, etc. in the recent past.
                </p>
                <p className='fw-bold fs-20'>About Thinkly</p>
                <p className='fs-18'>
                    The world is changing. Don’t get left behind. Thinkly is a platform for great world class content in bite-size. We have AI-powered 100-word summaries on business, health and wellness, sci-tech, geopolitics and pop-culture and more. Thinkly helps you stay updated & relevant about trending ideas, concepts and developments. Helping you make sense of the world around you.
                </p>
                <p className='fw-bold fs-20'>KEY HIGHLIGHTS</p>
                <p className='fs-18'>
                    <li>50+ global publications at your fingertips</li>
                    <li>Direct access to content from 50+ Nobel Laureates, 120+ Heads of State, 200+ independent thinkers</li>
                    <li>55+ interest categories including business, science & tech, health & wellness, environment, geopolitics, pop culture & more</li>
                    <li>AI-powered 100-word summaries of all the content be it text, video or audio</li>
                    <li>Get the latest news of the day via Thinkly Espresso which covers the trending developments that you need to know along with market update</li>
                    <li>Find out what to watch on OTT, sports & in theatres, as well as local events to go to via the Thinkly Sundowner daily</li>
                    <li>Learn about hard-to-understand concepts & ideas via Thinkly Explainers</li>
                    <li>Get 50 summaries a day to help you stay updated</li>
                    <li>Listen to articles with our text-to-speech feature</li>
                    <li>Read in Light Mode or Dark Mode, your choice</li>
                    <li>Change the font size to your comfort</li>
                    <li>Organise and customise your library</li>
                    <li>Bookmark the articles you want to read later or store them in your library</li>
                    <li>Distraction-free user experience</li>
                </p> */}
            </Card>
        </div>
    </div>)
}

export default LoveGuruFaq