import { createContext } from "react";

export const baseUrl = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_AZURE : process.env.REACT_APP_BASE_URL_AZURE
);

export const baseUrlThinkly = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_THINKLY : process.env.REACT_APP_BASE_URL_THINKLY
);  //thinkly.live



