import React, { useEffect, useState } from 'react'
import ParaByNameFromUrl from '../../common/paraByNameFromUrl'
import { useHistory } from 'react-router-dom'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import FirebaseApp from '../../thinklyLite/loginPage/firebase';

const PaymentFailed = () => {
    const history = useHistory();
    const [getpenName, setpenName] = useState()

    useEffect(() => {
        isSupported().then((result) => {
            if(result) {
                const analytics = getAnalytics(FirebaseApp)
                logEvent(analytics, 'STAR_FAILURE')
            }
        })
        const data = ParaByNameFromUrl('penname')
        setpenName(data)
    }, [])

    const handleWindowClose = () => {
        history.push(`/${getpenName}`)
    }

    return (<>
        <p className='text-center fs-20 fw-mid'>Payment Cancelled or Failed</p>
        <div className='row d-flex'>
            <input className='mx-auto mt-4 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 primary-bg-color px-4' type='button' value='OK' onClick={() => handleWindowClose()} />
        </div>
    </>)
}

export default PaymentFailed