import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import FirebaseApp from './firebase' //imp don't remove
import { useHistory } from "react-router-dom";
import { register } from './auth'
import { isMobile } from 'react-device-detect'
import '../css/login.css'
import '../../common/style.css'
import GmailLogo from '../Images/gmail-logo.svg'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'

const Login = (props) => {
    const history = useHistory();
    const emailValidate = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [form, setForm] = useState({ email: '' })
    const [ErrorMsg, setErrorMsg] = useState('')

    const handleSubmit = async (e) => {
        if (form.email.match(emailValidate)) {
            setErrorMsg()
            register(form)
            history.push({
                pathname: '/signUpSuccess',
                state: form.email
            })
        } else {
            setErrorMsg("Please provide a valid email ID")
        }
    }

    const handleGmailSignIn = () => {
        isSupported().then((result) => {
            if(result) {
                const analytics = getAnalytics(FirebaseApp)
                logEvent(analytics, 'Star_Google_Medium')
            }
        })
        const GoogleAuth = new firebase.auth.GoogleAuthProvider();
        GoogleAuth.addScope('profile')
        GoogleAuth.addScope('email')
        firebase.auth().signInWithPopup(GoogleAuth)
            .then(function (result) {
                console.log("inside .then of gmail sign in", result);
                var user = result.user;
                const arr = user._delegate.providerData
                let obj = arr.find(o => o.providerId === 'google.com');
                console.log(obj);
                window.localStorage.setItem('emailForSignIn', obj.email);
                history.push({ pathname: '/UserAuth', state: obj })
            }).catch(function (error) {
                console.log(error);
            });
    }


    return (<>
        <div className='container'>
            <div className='text-center' style={isMobile ? { marginTop: '100px', marginBottom: '135px' } : {}}>
                <text className='fs-16 ff-roboto'> Welcome back to</text>
                <h2 className='ff-roboto fs-30 fw-bold'>THINKLY STARS</h2> <br />

                {/* <text className='fw-mid fs-20 ff-roboto'>Login with Email</text>
                <p className='fs-15 ff-roboto'>We'll send you a magic sign-in link so <br /> you don't need to remember the password</p>
                <input type="text" name='email' id='email' className='email-link mt-3 fs-18 text-center ff-roboto' placeholder='Enter your Email ID' onChange={(e) => setForm({ ...form, email: e.target.value })} required /> <br />
                <label style={{ fontSize: '12px', color: 'red' }}>{ErrorMsg !== undefined && ErrorMsg !== null && ErrorMsg}</label> <br />
                <button type='button' className='button-sign-in fw-bold border-radius-4 fc-white border-none primary-bg-color height-button fs-18 ff-roboto' onClick={() => handleSubmit()}>Send Magic Link</button> <br />

                <div className='row justify-content-center py-3'>
                    <div className='col-2'><hr /></div>
                    <div className='col-' style={{ marginTop: '3px' }}>OR</div>
                    <div className='col-2'><hr /></div>
                </div> */}

                <button type='button' className='button-social-link mt-2 height-button fs-18 bg-white fc-black ff-roboto border-radius-4' onClick={() => handleGmailSignIn()}>
                    <img src={GmailLogo} alt="google" className='mr-2 mb-1' /> Continue with Gmail
                </button>
            </div>
        </div>
    </>)
}

export default Login;
