import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useForm } from "react-hook-form";
import ParaByNameFromUrl from '../../common/paraByNameFromUrl';

const UserInfo = () => {
    const emailValidate = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const { handleSubmit, formState } = useForm(); //for form submit
    const [starCount, setstarCount] = useState(0)
    const [finalAmount, setfinalAmount] = useState()
    const [Remarks, setRemarks] = useState()
    const [getPenName, setPenName] = useState()
    const [emailID, setemailID] = useState()
    const [numberInfo, setnumberInfo] = useState()
    const [productId, setproductId] = useState()

    useEffect(() => {
        const qty = ParaByNameFromUrl('qty')
        setstarCount(qty)
        const amount = ParaByNameFromUrl('amount')
        setfinalAmount(amount)
        const remarks = ParaByNameFromUrl('remarks')
        if (remarks !== undefined || 'undefined') {
            setRemarks(remarks)
        }
        const receiver = ParaByNameFromUrl('receiver')
        setPenName(receiver)
        const productID = ParaByNameFromUrl('productId')
        setproductId(productID)
        console.log("star count@@@@ ", qty);
        console.log("Total amount@@@@ ", amount);
        console.log("Remarks@@@@ ", remarks);
        console.log("receiver penName@@@@ ", receiver);
        console.log("product ID@@@@ ", productID);
    }, [])


    function onSubmit() {
        console.log("inside submit function");
        if (emailID !== undefined && numberInfo !== undefined) {
            if (emailID.match(emailValidate) && numberInfo.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                return new Promise(resolve => {
                    const form = document.createElement('form');
                    form.method = 'post'
                    form.action = process.env.REACT_APP_PAYMENT_GATEWAY
                    const data = [
                        { name: 'qty', value: starCount },
                        { name: 'receiver', value: getPenName },
                        { name: 'sender', value: "" },
                        { name: 'channel', value: "giftStars" },
                        { name: 'amount', value: finalAmount },
                        { name: 'remarks', value: Remarks },
                        { name: 'emailid', value: emailID },
                        { name: 'phone', value: numberInfo }
                    ]
                    for (let x = 0; x < data.length; x++) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'text';
                        hiddenField.name = data[x].name;
                        hiddenField.value = data[x].value;
                        hiddenField.style.display = 'none'
                        form.appendChild(hiddenField);
                    }
                    console.log("DONE", form);
                    document.body.appendChild(form);
                    form.submit();
                    $('#userContactInfo').modal('hide');
                    resolve();
                });
            } else {
                document.getElementById('infoPlease').innerHTML = 'Please Enter valid EmailID and Contact number'
            }
        } else {
            document.getElementById('infoPlease').innerHTML = 'Please Enter Email ID and Contact number to continue'
        }
    }

    return (
        <form name="paymentGateway" onSubmit={handleSubmit(onSubmit)}>
            <h5 className='text-center mb-5'>We need some details to send you a receipt</h5>
            <p className='text-center'>star count: {starCount}</p>
            <p className='text-center'>total amount: {finalAmount}</p>
            <p className='text-center'>Remarks: {Remarks}</p>
            <p className='text-center'>Receiver penName: {getPenName}</p>
            <p className='text-center'>product ID: {productId}</p>
            <input className='text-center' type='text' placeholder='Your email ID' value={emailID} onChange={(e) => setemailID(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
            <br /><br />
            <input className='text-center' type='text' placeholder='Your mobile number' value={numberInfo} onChange={(e) => setnumberInfo(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
            <br /><br />
            {<div id="infoPlease" className='error-msg'></div>}
            <div className='text-center'>
                <button type='submit' className='mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 primary-bg-color' >Continue</button>
            </div>
        </form>
    )
}

export default UserInfo