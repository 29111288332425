import { fetchAndActivate, getRemoteConfig, getValue } from "@firebase/remote-config";

export const RemoteConfiguration = async () => {
    const remoteConfig = getRemoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    const isFetched = await fetchAndActivate(remoteConfig)
    console.log("ThinklyConfiguration remote config", isFetched);
    if (!isFetched) {
        console.log("not fetched");
        let data = getValue(remoteConfig, "ThinklyConfiguration")
        window.sessionStorage.setItem("ThinklyConfigData", data._value)
    } else {
        console.log("fetched");
    }
}
