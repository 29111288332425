import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import HomePage from './index';
import MultiAccount from './multiAccount';
import CreateAccount from '../loginPage/createAccount';

const UserCheck = (props) => {
    const history = useHistory();
    const [JsonData, setJsonData] = useState(props.location.state)
    const [user_check, setuser_check] = useState('', 'none', 'single', 'multiple')
    const [accountUserID, setaccountUserID] = useState()
    const [accountData, setaccountData] = useState()
    const [user_status, setuser_status] = useState()

    useEffect(() => {
        if (JsonData !== undefined) {
            console.log("checkUser data passed from userAuth", JsonData);
            checkEmail()
        }
    }, [JsonData])

    function checkEmail() {
        setuser_status(JsonData.status)  //Success normally
        const response = JsonData.data.responseData
        const data = JSON.parse(response)
        console.log(data);
        if (JsonData.data.responseCode === '00') {  //user exist with 1 account type
            console.log("single user data########", data);
            // user exist but registered with email only then check penName availability and act accordingly
            if (data.UserDetails[0].PenName !== "" && data.UserDetails[0].PenName.length > 0) {
                const author_id = data.UserDetails[0].UserID
                window.sessionStorage.setItem('UserID', author_id);
                history.push({
                    pathname: '/thinkly-dashboard',
                    userID: author_id,
                    userStatus: JsonData.status
                })
            } else {
                history.push({
                    pathname: '/complete-your-profile',
                    state: JsonData.state,
                    updateCall: data.UserDetails[0].UserID
                })
            }
        } else if (JsonData.data.responseCode === '01') { //user exist with multiple account type
            setuser_check('multiple')
            console.log("multiple user data", data);
            setaccountData(data)
        } else if (JsonData.data.responseCode === '02') { // user not exist
            history.push({
                pathname: '/complete-your-profile',
                state: JsonData.state,
            })
        }
    }

    useEffect(() => { }, [accountData, accountUserID, user_status])


    return (<>
        <div className='container'>
            {user_check === 'multiple' ? <MultiAccount acc_list={accountData} userStatus={user_status} />
                : <div style={{ padding: '150px 0px', textAlign: 'center' }}> <CircularProgress aria-label="Loading..." /> </div>}
        </div>
    </>)
}

export default UserCheck
