import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { register } from './auth'
import Inbox from '../Images/Inbox.svg'

const LoginSuccess = (props) => {
    const [form, setForm] = useState({ email: props.location.state })

    useEffect(() => {
        console.log("data history", props.location.state);
    }, [])

    const handleResendMail = () => {
        register(form)
    }

    return (
        <div className='container'>
            <div className='text-center' style={isMobile ? { marginTop: '100px', marginBottom: '135px' } : {}}>
                <img src={Inbox} alt="Inbox" style={{ height: '100px' }} />
                <h2 className='mt-3' style={{ fontSize: '24px', fontWeight: 'bold' }}>Check your Email!</h2>
                <h2 style={{ fontSize: '22px' }}>{form.email}</h2> <br/>
                <p style={isMobile ? { fontSize: '18px' } : { fontSize: '18px', paddingLeft: '250px', paddingRight: '250px', lineHeight: '1.2' }}>To Login, click <b>Sign in to Thinkly</b> in the email we sent you.</p>
                <h6>Didn't receive the Email? <a href='#resendMail' onClick={() => handleResendMail()} >Resend Magic Link</a></h6>
            </div>
        </div>
    )
}

export default LoginSuccess
