import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import $ from 'jquery'
import { Avatar, Card, ListItemText } from '@material-ui/core'
import { AssignmentIndOutlined } from '@material-ui/icons'
import HomePage from '.';

const MultiAccount = (props) => {
    const history = useHistory();
    const [accountType, setaccountType] = useState()
    const [authorID, setAuthorID] = useState();
    const [switchPage, setSwitchPage] = useState(false);
    const [user_status, setuser_status] = useState()

    useEffect(() => {
        $("#user_account_type").modal('show');  // onload modal box open
        $('#user_account_type').modal({  // prevent of popup closing while click outside of box
            backdrop: 'static',
            keyboard: false
        })
        if (props.acc_list !== undefined && props.userStatus) {
            console.log('multi account user data@@@@@@@@', props.acc_list);
            setaccountType(props.acc_list)
            setuser_status(props.userStatus)
        }
    }, [props])

    const handleAccount = (index) => {
        console.log("userID from multi account page", accountType.UserDetails[index].UserID);
        $("#user_account_type").modal('hide')
        const user_id = accountType.UserDetails[index].UserID
        window.sessionStorage.setItem('UserID', user_id);
        // setAuthorID(user_id)
        // setSwitchPage(true)
        history.push({ pathname: '/thinkly-dashboard', userID: user_id, userStatus: user_status })
    }

    return (<>
        {/* {switchPage ? <HomePage userID={authorID} userStatus={user_status} /> : */}
        <div className='container' style={{ paddingTop: '300px' }}>
            <div id="user_account_type" class="modal fade in" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content modal-background">
                        <div class="modal-body p-2" style={{ height: "80vh" }}>
                            <div className="row">
                                <div className='text-center p-4'>
                                    {accountType !== undefined && accountType !== null && accountType.UserDetails.length > 0 && <>
                                        <h2>Welcome Back</h2>
                                        <p>Hey, we found multiple Thinkly accounts for your email <b>{accountType.UserDetails[0].EmailID}</b>. Choose any one to sign in. </p>
                                        <Card style={{ height: '55vh', overflow: 'auto', boxShadow: 'none', paddingTop: '10px' }} >
                                            {accountType.UserDetails.map((obj, index) => {
                                                return (<div className='row d-flex mb-3'>
                                                    <div className='col-8 mx-auto'>
                                                        <Card className="row d-flex py-1 account-list-card" onClick={() => handleAccount(index)}>
                                                            <div className='col-1 mx-auto my-auto'>
                                                                {obj.UserImage !== undefined ?
                                                                    <img src={obj.UserImage.charAt(0) === '@' ? obj.UserImage.substring(1) : obj.UserImage} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                                                    : <Avatar style={{ width: '44px', height: '44px' }} src={<AssignmentIndOutlined />} />}
                                                            </div>
                                                            <div className='col-10 text-left mx-auto pl-4'>
                                                                <ListItemText
                                                                    primary={<span style={{ fontSize: '16px' }}> {obj.PenName.charAt(0) === '@' ? obj.PenName.substring(1) : obj.PenName}</span>}
                                                                    secondary={<div className="row" style={{ marginLeft: '0px' }}>
                                                                        <span style={{ fontSize: '10px' }}> {obj.FirstName + obj.LastName} </span>
                                                                    </div>} />
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>)
                                            })}
                                        </Card>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default MultiAccount
