import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Header from './component/sharedThinkly/common/header';
import Footer from './component/sharedThinkly/common/footer';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-quill/dist/quill.snow.css';
import { isMobile } from "react-device-detect";

const display = (
  <BrowserRouter>
    <div style={{ overflowX: "hidden", backgroundColor: "#FFFFFF" }}>
      {window.location.href.indexOf('/payment/success') > 0 || window.location.href.indexOf('/payment/failed') > 0 ?
        <div className="mt-5"> </div> : <div style={isMobile ? { paddingBottom: '60px' } : { paddingBottom: '90px' }}>
          <Header />
        </div>
      }
      <App />
      {window.location.href.indexOf('/payment/success') > 0 || window.location.href.indexOf('/payment/failed') > 0 ?
        <div className="mb-5"></div> : <>
          {isMobile ? <div style={{ marginTop: '6rem' }}>
            <Footer />
          </div> : <Footer />}
        </>}
    </div>
  </BrowserRouter>
);
ReactDOM.render(display, document.getElementById("root"));

//the performance of your application using different metrics
//reportWebVitals performance relayer that allows you to measure and analyze
reportWebVitals(console.log("check project performance", display));
