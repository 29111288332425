import React, { useEffect, useState } from 'react'
import { Avatar, ListItemText } from "@material-ui/core";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import { isMobile } from 'react-device-detect';
import { useHistory } from "react-router-dom";

const Left = (props) => {
    const history = useHistory();
    const [GetSummaryDetails, setSummaryDetails] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.SummaryDetails !== undefined && props.SummaryDetails !== null) {
            setSummaryDetails(props.SummaryDetails);
        }
    }, []);

    useEffect(() => { }, [GetSummaryDetails]);

    return (
        <div>
            {GetSummaryDetails !== undefined && GetSummaryDetails !== null ? <>
                {GetSummaryDetails.publicationData !== null && <>
                    <div className="row body-content-align font-weight-bold header-font" style={{ fontSize: '22px' }}>About the Publication</div>
                    <div className="row mt-4 body-content-align" >
                        {GetSummaryDetails.publicationData.publicationImage !== undefined && GetSummaryDetails.publicationData.publicationImage !== null ?
                            <img onClick={() => history.push('publicationProfile')} src={GetSummaryDetails.publicationData.publicationImage.charAt(0) === '@' ? GetSummaryDetails.publicationData.publicationImage.substring(1) : GetSummaryDetails.publicationData.publicationImage} alt="user profile" style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer' }} />
                            : <Avatar src={<AssignmentIndOutlinedIcon />} />
                        }
                        <ListItemText style={{ marginTop: '6px', marginLeft: '10px' }}
                            primary={<div style={{ lineHeight: '10px' }} >
                                <span className="header-font" onClick={() => history.push('publicationProfile')} style={{ cursor: 'pointer' }}>
                                    <b> {GetSummaryDetails.publicationData.publicationName}. </b>
                                </span> &nbsp;
                                {isMobile ? <a className="connect subheader-font" href="#Subscribe" onClick={() => props.activeDownloadPannel()}> Subscribe</a>
                                    : <a className="connect header-font" href="#Subscribe" data-toggle="modal" data-target="#myModal" onClick={() => setShowModal(true)}> Subscribe</a>}
                            </div>}
                            secondary={<div className="row" style={{ marginLeft: '0px', lineHeight: '22px', cursor: 'pointer' }}>
                                <span className="subheader-font" onClick={() => history.push('publicationProfile')}>Author</span>
                            </div>}
                        />
                    </div>
                    <div className="row body-content-align right-content-font"> {GetSummaryDetails.publicationData.description} </div>
                </>}

                <div className="row body-content-align font-weight-bold header-font" style={{ marginTop: '60px', fontSize: '22px' }}>About the Author</div>
                <div className="row mt-4 body-content-align" >
                    {GetSummaryDetails.authorData.authorProfileImage !== undefined ?
                        <img onClick={() => history.push('UserProfile')} src={GetSummaryDetails.authorData.authorProfileImage.charAt(0) === '@' ? GetSummaryDetails.authorData.authorProfileImage.substring(1) : GetSummaryDetails.authorData.authorProfileImage} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer' }} />
                        : <Avatar src={<AssignmentIndOutlinedIcon />} />
                    }
                    <ListItemText style={{ marginTop: '6px', marginLeft: '10px' }}
                        primary={<div style={{ lineHeight: '10px' }} >
                            <span className="header-font" onClick={() => history.push('UserProfile')} style={{ cursor: 'pointer' }}>
                                <b> {GetSummaryDetails.authorData.authorPenName.charAt(0) === '@' ? GetSummaryDetails.authorData.authorPenName.substring(1) : GetSummaryDetails.authorData.authorPenName}. </b>
                            </span> &nbsp;
                            {isMobile ? <a className="connect subheader-font" href="#follow" onClick={() => props.activeDownloadPannel()}>Follow</a>
                                : <a className="connect header-font" href="#follow" data-toggle="modal" data-target="#myModal" onClick={() => setShowModal(true)}>Follow</a>}
                        </div>}
                        secondary={<div className="row" style={{ marginLeft: '0px', lineHeight: '22px', cursor: 'pointer' }}>
                            <span className="subheader-font" onClick={() => history.push('publicationProfile')}>Author</span>
                        </div>}
                    />
                </div>
                <div className="row body-content-align right-content-font" style={{ marginBottom: '10px' }}> {GetSummaryDetails.authorData.authorProfileText} </div>
            </> : 'no data'}
        </div>
    )
}

export default Left;

// const toggleVisible = () => {  //at first Ui will be hidden after few px scroll will get visible
//     const scrolled = document.documentElement.scrollTop;
//     if (!isMobile && scrolled > 300) {
//         setVisible(true);
//     } else if (!isMobile && scrolled <= 300) {
//         setVisible(false)
//     } else if (isMobile && scrolled > 50) {
//         setVisible(true);
//     } else if (isMobile && scrolled <= 50) {
//         setVisible(false)
//     }
// };

// window.addEventListener('scroll', toggleVisible);  //adding event listiner to toggele visible function

// <div className={props.imageCount === 0 ? "left-sec-align" : "left-sec-align js-scroll fade-in-bottom"} style={props.imageCount === 0 ? {} : { display: visible ? 'inline' : 'none', pointerEvents: visible ? 'auto' : 'none' }}>
//     {GetSummaryDetails !== undefined && GetSummaryDetails !== null ? <>
//         <div className="row body-content-align font-weight-bold header-font">About the Publication</div>
//         <div className="row mt-2 body-content-align">
//             {GetSummaryDetails.publicationData.publicationImage !== undefined ?
//                 <img src={GetSummaryDetails.publicationData.publicationImage.charAt(0) === '@' ? GetSummaryDetails.publicationData.publicationImage.substring(1) : GetSummaryDetails.publicationData.publicationImage} alt="user profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
//                 : <Avatar src={<AssignmentIndOutlinedIcon />} />
//             }
//             <ListItemText style={{ marginTop: '6px', marginLeft: '10px' }}
//                 primary={<div style={{ lineHeight: '10px' }} >
//                     <span className="header-font"><b> {GetSummaryDetails.publicationData.publicationName}. </b></span> &nbsp;
//                     {isMobile ? <a className="connect subheader-font" href="#Subscribe" onClick={() => props.activeDownloadPannel()}> Subscribe</a>
//                         : <a className="connect subheader-font" href="#Subscribe" data-toggle="modal" data-target="#myModal" onClick={() => setShowModal(true)}> Subscribe</a>}
//                 </div>}
//                 secondary={<div className="row" style={{ marginLeft: '0px', lineHeight: '22px' }}>
//                     <span className="subheader-font">Author</span>
//                 </div>}
//             />
//         </div>
//         <div className="row body-content-align right-content-font" style={{}} > {GetSummaryDetails.publicationData.description} </div>


//         <div className="row body-content-align font-weight-bold header-font" style={{ marginTop: '100px' }}>About the Author</div>
//         <div className="row mt-2 body-content-align">
//             {GetSummaryDetails.authorData.authorProfileImage !== undefined ?
//                 <img src={GetSummaryDetails.authorData.authorProfileImage.charAt(0) === '@' ? GetSummaryDetails.authorData.authorProfileImage.substring(1) : GetSummaryDetails.authorData.authorProfileImage} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
//                 : <Avatar src={<AssignmentIndOutlinedIcon />} />
//             }
//             <ListItemText style={{ marginTop: '6px', marginLeft: '10px' }}
//                 primary={<div style={{ lineHeight: '10px' }} >
//                     <span className="header-font">
//                         <b> {GetSummaryDetails.authorData.authorPenName.charAt(0) === '@' ? GetSummaryDetails.authorData.authorPenName.substring(1) : GetSummaryDetails.authorData.authorPenName}. </b>
//                     </span> &nbsp;
//                     {isMobile ? <a className="connect subheader-font" href="#follow" onClick={() => props.activeDownloadPannel()}>Follow</a>
//                         : <a className="connect subheader-font" href="#follow" data-toggle="modal" data-target="#myModal" onClick={() => setShowModal(true)}>Follow</a>}
//                 </div>}
//                 secondary={<div className="row" style={{ marginLeft: '0px', lineHeight: '22px' }}>
//                     <span className="subheader-font">Author</span>
//                 </div>}
//             />
//         </div>
//         <div className="row body-content-align right-content-font" style={{ marginBottom: '300px' }}> {GetSummaryDetails.authorData.authorProfileText} </div>
//     </> : 'no data'}
// </div>