import React, { useContext, useEffect, useState } from 'react'
import Axios from "axios";
import { baseUrl, baseUrlThinkly } from "../../apiContent/api.jsx";
import { useHistory } from "react-router-dom";
import { authenticateIsUser } from '../loginPage/auth'
import { CircularProgress } from '@material-ui/core';

const UserAuth = (props) => {
    const history = useHistory();
    const BASE_URL_THINKLY = useContext(baseUrlThinkly);
    // const [sourceName, setSourceName] = useState(props.location.source)
    // const [getUID, setUID] = useState(props.location.uid)
    // const [email, setemail] = useState(props.location.email)
    const [propsDataFromSignUp, setpropsDataFromSignUp] = useState(props.location.state)

    useEffect(() => {
        console.log("inside user auth@@@", props.location.state);
        async function fetchData() {
            var email = getParameterByName('email');
            console.log("filtered email from url -> calling authenticate isUser now", email);
            await authenticateIsUser(email)
            const status = window.sessionStorage.getItem('signInStatus')
            if (status === 'Success') {
                console.log("user signInStatus success", email);
                checkEmail(email, status)
            } else {
                console.log("user signInStatus failed");
                history.push('/error')
            }
        }
        if (propsDataFromSignUp !== undefined && propsDataFromSignUp.providerId === "google.com") {
            console.log("propsDataFromSignUp@@@ ", propsDataFromSignUp);
            checkEmail(propsDataFromSignUp.email, "Success")
        } else {
            fetchData()
        }
    }, []);

    function getParameterByName(name, url = decodeURIComponent(window.location.href)) {
        name = name.replace(/[\[\]]/g, '\\$&');  //name of key
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function checkEmail(email_id, signIn_status) {
        console.log("inside checkEmail api email_id and signIn_status", email_id, signIn_status);
        var config = {
            method: 'POST',
            data: { EmailID: email_id, MobileNo: "" }
        }
        Axios(`${BASE_URL_THINKLY}User/CheckUser`, config)
            .then((res) => {
                console.log("CheckUser response", res.data);
                history.push({
                    pathname: '/checkUser',
                    // state: { data: res.data, status: signIn_status, UID: getUID, sourceName: sourceName }
                    state: { data: res.data, status: signIn_status, state: propsDataFromSignUp }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (<div style={{ padding: '150px 0px', textAlign: 'center' }}>
        <CircularProgress aria-label="Loading..." />
    </div>)
}

export default UserAuth

