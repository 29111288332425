import React, { useContext, useState, useEffect } from 'react'
import Axios from "axios";
import Profile_pic from '../images/Rectangle.png'
import { Avatar, Card, CardMedia, CardHeader, CardContent } from '@material-ui/core'
import '../css/publicationProfile.css'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Quote from '../images/double-quote.svg'
import { isMobile } from 'react-device-detect'
import PublicationMob from './publicationMobDetailed'
import { baseUrl } from "../../apiContent/api.jsx";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const PublicationProfile = () => {
    const BASE_URL = useContext(baseUrl);
    const [getPublicationDetail, setPublicationDetail] = useState()
    const [getPublicationThinkly, setPublicationThinkly] = useState()

    useEffect(() => {
        // fetchPublicationDetail()
        fetchPublicationThinklies()
    }, [])

    function fetchPublicationThinklies() {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": "21723"
            },
            data: {
                "PublicationID": "907",
                "UserID": "2444",
                "StartIndex": 0,
                "EndIndex": 10
            }
        };
        Axios(`${BASE_URL}Thinkly/GetPublicationThinklies/`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    console.log("setPublicationThinkly @@@@@@@@", res.data.responseData);
                    setPublicationThinkly(res.data.responseData)
                } else {
                    console.log("not found", res.data);
                }
            })
            .catch((err) => {
                console.log("setPublicationThinkly error in catch", err);
            });
    }

    return (<>
        {isMobile ? <PublicationMob /> :
            <div className='container'>
                <div className='row d-flex'>
                    <img src={Profile_pic} className='mx-auto' style={{ width: '60%' }} />
                </div>
                <div className='row text-center'>
                    <div className='col-12'>
                        <span style={{ fontFamily: 'Lore', fontSize: '56px', fontWeight: 'bold' }}>The Story of Spiderman</span>
                        <p style={{ fontSize: '32px', fontWeight: 'bold' }}>written by me and</p>
                        <p style={{ fontSize: '20px', fontWeight: '500' }}>Lorem ipsum dolor sit amet, consectetur, sed do eiusmod tempor incididunt ut labore</p>
                    </div>
                </div>
                <div className='vertical-line my-4'></div>
                <div className='row d-flex px-4 story-content'>
                    <span className='mx-auto'>Long Description 1</span>
                    <p className='my-4'>"Lorem ipsum dolor sit amet, consectetur, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <button type="button" class="btn mx-auto">Subscribe</button>
                </div>
                <div className='vertical-line mt-5 mb-4'></div>
                {/* thinklies in this publication */}
                <div className='row'>
                    <div className='col-12 text-center mb-2'>
                        <p className='p-heading'>Thinklies in this Publication</p>
                    </div>
                    {getPublicationThinkly !== undefined}
                    <div className='col-4'>
                        <Card className='t-in-p'>
                            <div className='row d-flex'>
                                <div className='col-2'>
                                    <img src={Profile_pic} className='publilcation-image' />
                                </div>
                                <div className='col-8 my-auto ml-3'>
                                    <p style={{ fontSize: '18px', lineHeight: 'normal', marginBottom: '2px' }}> Thinkly name - name of thinkly </p>
                                </div>
                            </div>
                        </Card>
                    </div>
                    
                </div>
                <div className='text-center'>
                    <p className='mt-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '#ff900f' }}>+MORE</p>
                </div>
                <div className='vertical-line mt-5 mb-4'></div>
                {/* meet the authors */}
                <div className='row'>
                    <div className='col-12 text-center'>
                        <p className='p-heading'>Meet the Authors</p>
                    </div>
                    <div className='col-4'>
                        <Card className="t-in-p">
                            <div className='row mb-3'>
                                <Avatar className='mx-auto ma-profile' src={Profile_pic} alt="author profile" />
                            </div>
                            <div className='text-center mb-2'>
                                <text style={{ fontSize: '30px', fontWeight: 'bold' }}>Author Name</text>
                            </div>
                            <p style={{ fontSize: '18px' }}>Lorem ipsum dolor sit amet, consectetur, sed labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat. Duis aute irure dolor </p>
                        </Card>
                    </div>
                    <div className='col-4'>
                        <Card className="t-in-p">
                            <div className='row mb-3'>
                                <Avatar className='mx-auto ma-profile' src={Profile_pic} alt="author profile" />
                            </div>
                            <div className='text-center mb-2'>
                                <text style={{ fontSize: '30px', fontWeight: 'bold' }}>Author Name</text>
                            </div>
                            <p style={{ fontSize: '18px' }}>Lorem ipsum dolor sit amet, consectetur, sed labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat. Duis aute irure dolor </p>
                        </Card>
                    </div>
                    <div className='col-4'>
                        <Card className="t-in-p">
                            <div className='row mb-3'>
                                <Avatar className='mx-auto ma-profile' src={Profile_pic} alt="author profile" />
                            </div>
                            <div className='text-center mb-2'>
                                <text style={{ fontSize: '30px', fontWeight: 'bold' }}>Author Name</text>
                            </div>
                            <p style={{ fontSize: '18px' }}>Lorem ipsum dolor sit amet, consectetur, sed labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat. Duis aute irure dolor </p>
                        </Card>
                    </div>
                </div>
                <div className='vertical-line mt-4 mb-5'></div>
                {/* more publication from abc */}
                <div className=''>
                    <div className='row d-flex mb-4'>
                        <p className='mx-auto p-heading'>More Publications by ABC</p>
                    </div>
                    <Carousel responsive={responsive} >
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                    </Carousel>
                </div>
                <div className='vertical-line my-4'></div>
                {/* testimonials */}
                <div className='row'>
                    <div className='col-12 text-center'>
                        <p className='p-heading'>Testimonials</p>
                    </div>
                    <div className='col-6 mb-4'>
                        <Card className="t-in-p">
                            <CardContent>
                                <img src={Quote} className='mb-3' alt='double quote' />
                                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                            </CardContent>
                            <CardHeader style={{ marginTop: '-30px' }}
                                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                                titleTypographyProps={{ variant: 'h6' }}
                                title="User name"
                                subheaderTypographyProps={{ variant: 'subtitle2' }}
                                subheader="About the user"
                            />
                        </Card>
                    </div>
                    <div className='col-6'>
                        <Card className="t-in-p">
                            <CardContent>
                                <img src={Quote} className='mb-3' alt='double quote' />
                                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                            </CardContent>
                            <CardHeader style={{ marginTop: '-30px' }}
                                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                                titleTypographyProps={{ variant: 'h6' }}
                                title="User name"
                                subheaderTypographyProps={{ variant: 'subtitle2' }}
                                subheader="About the user"
                            />
                        </Card>
                    </div>
                    <div className='col-6'>
                        <Card className="t-in-p">
                            <CardContent>
                                <img src={Quote} className='mb-3' alt='double quote' />
                                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                            </CardContent>
                            <CardHeader style={{ marginTop: '-30px' }}
                                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                                titleTypographyProps={{ variant: 'h6' }}
                                title="User name"
                                subheaderTypographyProps={{ variant: 'subtitle2' }}
                                subheader="About the user"
                            />
                        </Card>
                    </div>
                    <div className='col-6'>
                        <Card className="t-in-p">
                            <CardContent>
                                <img src={Quote} className='mb-3' alt='double quote' />
                                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                            </CardContent>
                            <CardHeader style={{ marginTop: '-30px' }}
                                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                                titleTypographyProps={{ variant: 'h6' }}
                                title="User name"
                                subheaderTypographyProps={{ variant: 'subtitle2' }}
                                subheader="About the user"
                            />
                        </Card>
                    </div>
                </div>
                <div className='vertical-line my-5'></div>
                {/* publication profile  */}
                <div className='row d-flex'>
                    <div className='col-5 mx-auto'>
                        <Card className="t-in-p">
                            <div className='row mb-3'>
                                <Avatar className='mx-auto pay-pub-profile' src={Profile_pic} alt="author profile" />
                            </div>
                            <div className='text-center mb-2'>
                                <text style={{ fontSize: '32px', fontWeight: 'bold' }}>Amazing Spiderman</text>
                                <p style={{ fontSize: '28px', fontWeight: 'bold' }}>Rs. 199</p>
                            </div>
                            <p style={{ fontSize: '18px' }}>Lorem ipsum dolor sit amet, sed labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip commodo consequat. </p>
                            <button type='button' className='btn mt-5' style={{ width: '100%', fontSize: '22px', fontWeight: 'bold', background: '#2baadf', color: '#ffffff' }}>Pay Rs-199.00 (Includes 18% GST)</button>
                        </Card>
                    </div>
                </div>
                <div className='vertical-line my-5'></div>
                {/* you may also like */}
                <div className=''>
                    <div className='row d-flex mb-4'>
                        <p className='mx-auto p-heading'>You may also like</p>
                    </div>
                    <Carousel responsive={responsive} >
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                        <Card className="mb-4 morePub-card">
                            <CardMedia component="img" height="250" image={Profile_pic} alt="publication profile" className='morePub-cardmedia' />
                            <div className='px-2 pb-2'>
                                <text style={{ fontSize: '24px', fontWeight: 'bold' }}>Publication Name</text> <br />
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
                                <a href='#morepublication' style={{ color: '#2baadf', fontSize: '14px' }}>View the Publication</a>
                            </div>
                        </Card>
                    </Carousel>
                </div>
            </div>
        }
    </>)
}

export default PublicationProfile
