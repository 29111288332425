import React, { useEffect, useState } from 'react'
import '../css/homePage.css';
import Image from '../Images/social-tree_rafiki.svg';
import { CircularProgress } from '@material-ui/core';
import Feed from './feed';
import NewPublication from './newPublication';

const NoData = (props) => {
    const [showPublication, setshowPublication] = useState(false);
    const [AuthorID, setAuthorID] = useState()
    const [profileData, setProfileData] = useState();

    useEffect(() => {
        // will check thinkly count here first in casse count 0 then will show No data page else redirect to thinkly Feed page
        console.log("propsData value in no data page ", props.authorID, props.profileJson);
        if (props.authorID !== undefined && props.profileJson !== undefined) {
            console.log("props data successed in noDataPage@@@@@@", props.authorID, props.profileJson);
            setAuthorID(props.authorID)
            setProfileData(props.profileJson)
        }
    }, [])


    return (<>
        <div className='text-center'>
            <text className='fs-15'>WELCOME TO</text>
            <h2 className='fs-30'>Thinkly Lite</h2>
            <p className='fs-20'>One stop solution for all your creator needs</p>
            <img src={Image} alt="noData" style={{ width: 'auto', height: '280px' }} />
            <h5 className='my-3 fs-20 mb-2'>Create a publication to post a Thinkly</h5>
            <button className='fw-bold border-radius-4 fc-white border-none primary-bg-color height-button fs-18 ff-roboto w-50' data-toggle="modal" data-target="#newPublication" onClick={() => setshowPublication(true)} >New Publication</button>
        </div>
        {showPublication && <NewPublication show={showPublication} authorID={AuthorID} />}
    </>)
}

export default NoData
