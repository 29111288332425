import React from "react";
import { Route, Switch } from "react-router-dom";
import './App.css';
import DetailedPage from "./component/sharedThinkly/detailedPage/index";
import UserProfile from "./component/sharedThinkly/userProfile/index";
import Login from "./component/thinklyLite/loginPage/login";
import ThinkltyLiteHome from "./component/thinklyLite/homePage/index";
import PublicationProfile from "./component/sharedThinkly/publicationProfile";
import LoginSuccess from "./component/thinklyLite/loginPage/loginSuccess";
import UserCheck from "./component/thinklyLite/homePage/checkUser";
import ErrorPage from "./component/thinklyLite/loginPage/error";
import UserAuth from "./component/thinklyLite/homePage/userAuth";
import SignUp from "./component/thinklyLite/loginPage/signUp";
import CreateAccount from "./component/thinklyLite/loginPage/createAccount";
import PaymentSuccess from "./component/sharedThinkly/userProfile/paymentSuccess";
import PaymentFailed from "./component/sharedThinkly/userProfile/paymentFailed";
import ThinklyLogin from "./component/thinklyLite/loginPage";
import CreateProfileShare from "./component/thinklyLite/loginPage/createProfileShare";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import Dashboard from "./component/thinklyLite/userDashboard";
import Thankyou from "./component/common/thankYouShare";
import UserInfo from "./component/sharedThinkly/userProfile/userInfo";
require('dotenv').config()

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signUpSuccess" component={LoginSuccess} />
        <Route exact path="/UserAuth" component={UserAuth} />
        {/* <Route exact path="/error" component={ErrorPage} /> */}
        <Route exact path="/publicationProfile" component={PublicationProfile} />
        <Route exact path="/checkUser" component={UserCheck} />
        
        <Route exact path="/payment-gateway" component={UserInfo} />

        <Route exact path="/complete-your-profile" component={CreateAccount} />
        <Route exact path="/thinkly-dashboard" component={ThinkltyLiteHome} />
        <Route exact path="/payment/success" component={PaymentSuccess} />
        <Route exact path="/payment/failed" component={PaymentFailed} />
        <Route exact path='/account/Created' component={CreateProfileShare} />
        <Route exact path="/:penName" component={UserProfile} />
        <Route exact path="/:penName/dashboard" component={Dashboard} />
        <Route exact path="/:penName/thankyou" component={Thankyou} />
        <Route exact path="/thinkly/:title/:ID" component={DetailedPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
