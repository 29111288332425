import React, { useContext, useEffect, useState } from 'react'
import $ from 'jquery'
import { useHistory } from 'react-router-dom'
import ShareLink from './shareLink';
import Axios from 'axios'
import { baseUrl } from '../apiContent/api';

const Thankyou = (props) => {
    const BASE_URL = useContext(baseUrl)
    const history = useHistory();
    const [profileImage, setprofileImage] = useState()
    const [userName, setuserName] = useState()

    useEffect(() => {
        var url = window.location.href
        const last = url.split("/")
        const name = last[last.length - 2] //for find 2nd / from last index of url
        UserData(name)
    }, [])


    const UserData = (penName) => {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": "3223"
            },
        };
        Axios.get(`${BASE_URL}User/GetDetailsByPenName/${penName}`, config)
            .then((res) => {
                console.log("Get user Details By PenName@@@@@", res.data);
                if (res.data.responseCode === '00') {
                    console.log("inside Get User Profile details@@@@@@@@", res.data.responseData);
                    const response = res.data.responseData.Details.profileDetails
                    var name = response.penName.charAt(0) === '@' ? response.penName.substring(1) : response.penName
                    var image = response.profileImage.charAt(0) === '@' ? response.profileImage.substring(1) : response.profileImage
                    setuserName(name)
                    setprofileImage(image)
                }
            })
            .catch((err) => {
                console.log("getUserProfileDateils error in catch", err);
            });
    }

    return (<>
        <div class="modal-body text-center">
            <h2 className='fs-30 fw-bold'>Your page is live!</h2>
            <h3 className='fs-20'>Share it with the world.</h3>
            <img className='my-3' src={profileImage} alt='profile' style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'none' }} />
            <h2 className='fs-20 fw-bold'>{userName}</h2>
            {userName !== undefined && <ShareLink linkUrl={userName} />}
        </div>
    </>)
}

export default Thankyou