import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import FirebaseApp from './firebase' //imp don't remove
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect'
import '../css/login.css'
import GmailLogo from '../Images/gmail-logo.svg'
import Header from '../../sharedThinkly/common/header';

const SignUp = (props) => {
    const history = useHistory();

    useEffect(() => {
        console.log('inside sign up page', window.location.href);
        const str = 'https://uat.loveguru.thinkly.me/'
        if (window.location.href == process.env.REACT_APP_LOVEGURU) {
            console.log('considition fulfuill');
            window.location.replace(process.env.REACT_APP_REPLACED_LOVEGURU);
        }
    }, [])


    const handleGmailSignIn = () => {
        const GoogleAuth = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(GoogleAuth)
            .then(function (result) {
                console.log("inside .then of gmail sign in", result);
                var user = result.user;
                const arr = user._delegate.providerData
                let obj = arr.find(o => o.providerId === 'google.com');
                console.log(obj);
                window.localStorage.setItem('emailForSignIn', obj.email);
                history.push({ pathname: '/UserAuth', state: obj })
            }).catch(function (error) {
                console.log(error);
            });
    }

    return (<>
        <Header />
        <div className='container'>
            <div className='text-center' style={isMobile ? { marginTop: '100px', marginBottom: '135px' } : {}}>
                <text className='fs-16 ff-roboto'> Welcome to</text>
                <h2 className='ff-roboto fs-30 fw-bold'>THINKLY STARS</h2> <br />
                <p className='font-weight-bold fs-20 ff-roboto'>Create New Account</p>
                <button type='button' className='button-social-link mt-2 height-button fs-18 bg-white fc-black ff-roboto border-radius-4' onClick={() => handleGmailSignIn()}>
                    <img src={GmailLogo} alt="google" className='mr-2 mb-1' /> Continue with Gmail</button>
            </div>
        </div>
    </>)
}

export default SignUp;
